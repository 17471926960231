<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="less">
//ver: process.env.VUE_APP_VER

#app{
  padding:0;
  margin:0;
}
</style>
<script>
export default {
  // data () {
  //   return {
  //     ver: process.env.VUE_APP_VER
  //   }
  // }

}

</script>
